import React, { Component } from 'react';

export class War extends Component {
    render() {
        return (
            <section class="contents" style={{height: "900px"}}>
                
            </section>
        );
    }
}

export default War;